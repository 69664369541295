.wrapperLeadCapture {
  // width: 100vw;
  min-height: calc(100vh - 70px);
  // max-height: calc(100vh - 70px);
  position: relative;
  @media (max-width: 768px) {
    min-height: calc(100vh - 140px);
    max-height: max-content;
    position: static;
  }
}
.wrapperLeadCaptureProfile {
  position: relative;
  @media (max-width: 768px) {
    max-height: max-content;
  }
}

.v3BackgroundColor {
  @media (min-width: 768px) {
    background-color: #d7d1fa;
  }
}

.anlysisBackColor {
  background-color: #faf7f2;
}

.applyColorOnBody {
  body {
    background-color: #faf7f2;
  }
}

.fieldsContainer {
  z-index: 1;
  @media (min-width: 769px) {
    margin: 0 auto;
    max-width: 650px;
    width: 650px;
  }
}

.courseBadge {
  display: table;
  border-radius: 12px;
}

.v8Wrapper {
  height: auto;
  min-height: calc(100vh - 70px);
  background-image: url("https://leapassets.s3.ap-south-1.amazonaws.com/cdn-images/v8Background.svg");
  background-repeat: no-repeat;
  background-size: 100vw 110vh;
  @media (max-width: 768px) {
    max-height: max-content;
    position: static;
    background-size: contain;
    background-size: 100vw 100vh;
  }
}

.v8ContainerMover {
  border: 1px solid #dedede;
  width: 572px;
  height: auto;
  min-height: 681px;
  @media (max-width: 768px) {
    width: 100%;
    border: none;
    height: auto;
    min-height: 600px;
  }
}
.knowMore {
  position: absolute;
  bottom: 74px;
  width: 169px;
  height: 54px;
  border-radius: 100px;
  box-shadow: 0px 4px 20px rgba(196, 194, 255, 0.5);
  @media (max-width: 768px) {
    bottom: 60px;
  }
}
.thinkingPopup {
  background-color: white;

  width: 405px;
  height: 52px;
  padding: 8px 32px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 23px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  @media (max-width: 768px) {
    width: 280px;
    height: 38px;
    padding: 8px 15px;
  }
}

.thinkingPopup::before {
  content: "";
  position: absolute;
  display: block;
  width: 0px;
  left: 32px;
  bottom: -15px;
  border-width: 22px 13px 0px;
  border-right-style: solid;
  border-left-style: solid;
  border-right-color: transparent;
  border-left-color: transparent;
  border-image: initial;
  border-bottom-style: initial;
  border-bottom-color: initial;
  border-top-style: solid;
  border-top-color: white;
  transform: translate(0%, calc(0% + 4px));
  border-radius: 2px;
  @media (max-width: 768px) {
    border-width: 15px 8px 0px;
    left: 40px;
    bottom: -8px;
  }
}

.arrowBox {
  width: 397px;
  height: 95px;
  border-radius: 16px;
  position: relative;
  background: #6764db;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 77%;
  }
}
.arrowBox:after {
  right: 100%;
  top: 50%;
  bottom: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(103, 100, 219, 0);
  border-right-color: #6764db;
  border-width: 10px;
  margin-top: -13px;
}

.infoCard {
  box-shadow: 0px 8px 12px rgba(77, 73, 254, 0.05);
  border: 1px solid #ececec;
  padding-bottom: 25px;
  border-radius: 6px;
  margin-top: 20px;
}

.knowMoreContent {
  height: 354px;
  @media (max-width: 768px) {
    height: auto;
    min-height: 264px;
  }
}

//ielts to counselling page
.counsellingBanner {
  background: linear-gradient(
    151.48deg,
    rgba(255, 222, 192, 0.2) 1.34%,
    rgba(152, 128, 247, 0.2) 101.7%
  );

  background-image: url("https://leapassets.s3.ap-south-1.amazonaws.com/cdn-images/backgroundDesk.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 768px) {
    background-image: url("https://leapassets.s3.ap-south-1.amazonaws.com/cdn-images/backgroundMob.png");
    margin-bottom: 20px;
  }
}
.button {
  box-shadow: 0px 4px 16px rgba(59, 43, 154, 0.2);
  border-radius: 6px;
}

.newWrapper {
  border-radius: 5px 5px 0px 0px;
  background-image: url("https://leapassets.s3.ap-south-1.amazonaws.com/cdn-images/topBanner1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 768px) {
    background-image: url("https://leapassets.s3.ap-south-1.amazonaws.com/cdn-images/topBannerMob.png");
  }
}
.lastCompo {
  background-color: white;
  box-shadow: 0px 4px 20px rgba(115, 130, 185, 0.25);
  border-radius: 0px 0px 5px 5px;

  @media only screen and (max-width: 768px) {
    box-shadow: none;
  }
}

.newWrapperVariant1 {
  border-radius: 5px 5px 0px 0px;
  background-image: url("https://leapassets.s3.ap-south-1.amazonaws.com/bannerVariant1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0px 4px 20px rgba(115, 130, 185, 0.25);
  @media only screen and (max-width: 768px) {
    background-image: url("https://leapassets.s3.ap-south-1.amazonaws.com/bannerVariant1Mob.png");
    background-size: cover;
  }
}
.bac {
  min-height: calc(100vh - 65px);
}

.howLeapHelp {
  position: relative;
  box-shadow: 0px 4px 30px rgba(115, 130, 185, 0.25);
  padding: 30px 40px;

  @media only screen and (max-width: 768px) {
    padding: 34px 27px;
    padding-top: 103px;
    margin-bottom: 100px;
  }
}
.emoji {
  position: absolute;
  top: 13px;
  right: 27px;

  @media only screen and (max-width: 768px) {
    width: 99px;
    height: 76px;
    left: 27px;
  }
}
.optionStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 288px;
  height: 52px;
  background: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  border: 1px solid #969696;
  color: #000000;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 19px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: 0;
  }
}

.selectedOptionStyle {
  background: #f5f3ff;
  border: 1px solid #443eff;
  color: #443eff;
}

.buttonWithArrow {
  display: flex;
  font-family: Arial, Helvetica, sans-serif;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 10px;

  width: 174px;
  height: 52px;
  box-shadow: 0px 10px 20px rgba(125, 78, 224, 0.2);
  border-radius: 4px;

  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
  color: #ffffff;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}


